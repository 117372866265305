import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { AccessCheck } from "@particles/index"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { CLIENTS_ROUTES } from "@routes/clients"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    CLIENTS_LIST_CONTROLS_ACTIVE_TAB,
    CLIENTS_LIST_CONTROLS_KEYWORD_SEARCH,
    CLIENTS_LIST_CONTROLS_ORDERING,
    CLIENTS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { ClientsTabs } from "./ClientTableViewTabs"

export default function ClientTableView() {
    const [createRoute] = useSlugExtractor([CLIENTS_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={CLIENTS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={CLIENTS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={CLIENTS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={CLIENTS_LIST_CONTROLS_ORDERING}
            listTitle="Clients"
            objectType="client"
            objectCreateRoute={createRoute}
            objectIndexName="Client"
            objectName="Client"
            defaultActivePhase="active"
            isRowClickable={hasPermission("clients_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("clients_create_permission", PERMISSION_LEVEL.FULL)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="active" title="Active" />
                            <TableViewPhase phase="inactive" title="Inactive" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <ClientsTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<Client>
                    accessorKey="name"
                    header="Client"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Client>
                    accessorKey="client_type_label"
                    header="Type"
                    size={112}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="other"
                />
                <Table.Column<Client>
                    accessorKey="service_locations_display"
                    header="Location"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Client>
                    accessorKey="default_invoice_net"
                    header="Terms"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.PaymentTermsCell terms={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<Client>
                    accessorKey="credit_limit"
                    header="Credit Limit"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <AccessCheck entitlements={["entitlement_invoices_enabled"]}>
                    <Table.Column<Client>
                        accessorKey="outstanding_balance"
                        header="Balance Due"
                        size={144}
                        cell={({ getValue, row }) => (
                            <Table.Cells.NumericalDataCell
                                value={getValue()}
                                ignoreZero={true}
                                type="currency"
                                isAlerting={
                                    row.original.credit_limit !== null &&
                                    parseFloat(row.original.outstanding_balance) >
                                        parseFloat(row.original.credit_limit)
                                }
                            />
                        )}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                </AccessCheck>
                <Table.Column<Client>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as ClientLabel[])?.map((label: ClientLabel) => label.name)}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <AccessCheck entitlements={["entitlement_estimates_enabled"]}>
                    <Table.Column<Client>
                        accessorKey="current_estimates_count"
                        header="Current Est."
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                </AccessCheck>
                <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                    <Table.Column<Client>
                        accessorKey="current_jobs_count"
                        header="Current Jobs"
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                </AccessCheck>
                <AccessCheck entitlements={["entitlement_invoices_enabled"]}>
                    <Table.Column<Client>
                        accessorKey="current_invoices_count"
                        header="Current Inv."
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                </AccessCheck>
                <Table.Column<Client>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
