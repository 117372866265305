import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import { renderServiceLocationString } from "@legacy/clients/utils/utils";
import Spinner from "@legacy/core/components/Spinner";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import debounce from "debounce-promise";
import { Component } from "react";
import deepcopy from "rfdc";
import ClientForm from "../clients/forms/ClientForm";
import ContactForm from "../clients/forms/ContactForm";
import ServiceLocationForm from "../clients/forms/ServiceLocationForm";
import { ClientTypes, CustomIDGenerationModes, LineItemUnitTypes, PriceBookItemTypes } from "../core/utils/enums";
import { currencyFormatter, getCurrencySymbol, historyHasState, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import EquipmentForm from "../equipment/forms/EquipmentForm";
import PriceBookItemForm from "../pricebook/forms/PriceBookItemForm";
import { getPricebookTaxById } from "../pricebook/utils/utils";
import JobForm from "./forms/JobForm";

dayjs.extend(timezone)


const FORM_MODES = {
    ADD_JOB: "ADD_JOB",
    EDIT_JOB: "EDIT_JOB",
    ADD_CLIENT: "ADD_CLIENT",
    EDIT_CLIENT: "EDIT_CLIENT",
    ADD_SERVICE_LOCATION: "ADD_SERVICE_LOCATION",
    EDIT_SERVICE_LOCATION: "EDIT_SERVICE_LOCATION",
    ADD_PRICEBOOKITEM_SERVICE: "ADD_PRICEBOOKITEM_SERVICE",
    EDIT_PRICEBOOKITEM_SERVICE: "EDIT_PRICEBOOKITEM_SERVICE",
    ADD_PRICEBOOKITEM_TAX: "ADD_PRICEBOOKITEM_TAX",
    EDIT_PRICEBOOKITEM_TAX: "EDIT_PRICEBOOKITEM_TAX",
    ADD_EQUIPMENT: "ADD_EQUIPMENT",
    ADD_CONTACT: "ADD_CONTACT",
    EDIT_CONTACT: "EDIT_CONTACT",
}

const FORM_MODE_SUBTITLES = {
    ADD_JOB: "Add Job Details",
    EDIT_JOB: "Edit Job Details",
    ADD_CLIENT: "New Client",
    EDIT_CLIENT: "Edit Client",
    ADD_SERVICE_LOCATION: "Add Service Location",
    EDIT_SERVICE_LOCATION: "Edit Service Location",
    ADD_PRICEBOOKITEM_SERVICE: "Add PriceBook Service",
    EDIT_PRICEBOOKITEM_SERVICE: "Edit PriceBook Service",
    ADD_PRICEBOOKITEM_TAX: "Add PriceBook Tax",
    EDIT_PRICEBOOKITEM_TAX: "Edit PriceBook Tax",
    ADD_EQUIPMENT: "Log New Equipment",
    ADD_CONTACT: "Add Contact",
    EDIT_CONTACT: "Edit Contact",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_JOB: "flex",
    EDIT_JOB: "flex",
    ADD_CLIENT: "none",
    EDIT_CLIENT: "none",
    ADD_SERVICE_LOCATION: "none",
    EDIT_SERVICE_LOCATION: "none",
    ADD_PRICEBOOKITEM_SERVICE: "none",
    EDIT_PRICEBOOKITEM_SERVICE: "none",
    ADD_PRICEBOOKITEM_TAX: "none",
    EDIT_PRICEBOOKITEM_TAX: "none",
    ADD_EQUIPMENT: "none",
    ADD_CONTACT: "none",
    EDIT_CONTACT: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_JOB, FORM_MODES.EDIT_JOB]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_CLIENT,
    FORM_MODES.EDIT_CLIENT,
    FORM_MODES.ADD_SERVICE_LOCATION,
    FORM_MODES.EDIT_SERVICE_LOCATION,
    FORM_MODES.ADD_PRICEBOOKITEM_SERVICE,
    FORM_MODES.EDIT_PRICEBOOKITEM_SERVICE,
    FORM_MODES.ADD_PRICEBOOKITEM_TAX,
    FORM_MODES.EDIT_PRICEBOOKITEM_TAX,
    FORM_MODES.ADD_EQUIPMENT,
    FORM_MODES.ADD_CONTACT,
    FORM_MODES.EDIT_CONTACT,
]

const CLIENT_FORM_MODES = [
    FORM_MODES.ADD_CLIENT,
    FORM_MODES.EDIT_CLIENT,
]

const SERVICE_LOCATION_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_LOCATION,
    FORM_MODES.EDIT_SERVICE_LOCATION,
]

const PRICEBOOK_SERVICE_FORM_MODES = [
    FORM_MODES.ADD_PRICEBOOKITEM_SERVICE,
    FORM_MODES.EDIT_PRICEBOOKITEM_SERVICE,
    FORM_MODES.ADD_PRICEBOOKITEM_TAX,
    FORM_MODES.EDIT_PRICEBOOKITEM_TAX,
]

const EQUIPMENT_FORM_MODES = [
    FORM_MODES.ADD_EQUIPMENT,
]

const CONTACT_FORM_MODES = [
    FORM_MODES.ADD_CONTACT,
    FORM_MODES.EDIT_CONTACT,
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_JOB: "jobData",
    EDIT_JOB: "jobData",
    ADD_CLIENT: "clientData",
    EDIT_CLIENT: "clientData",
    ADD_SERVICE_LOCATION: "serviceLocationData",
    EDIT_SERVICE_LOCATION: "serviceLocationData",
    ADD_PRICEBOOKITEM_SERVICE: "priceBookItemData",
    EDIT_PRICEBOOKITEM_SERVICE: "priceBookItemData",
    ADD_PRICEBOOKITEM_TAX: "priceBookItemData",
    EDIT_PRICEBOOKITEM_TAX: "priceBookItemData",
    ADD_EQUIPMENT: "equipmentData",
    ADD_CONTACT: "contactData",
    EDIT_CONTACT: "contactData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_JOB: "submittingJob",
    EDIT_JOB: "submittingJob",
    ADD_CLIENT: "submittingClient",
    EDIT_CLIENT: "submittingClient",
    ADD_SERVICE_LOCATION: "submittingServiceLocation",
    EDIT_SERVICE_LOCATION: "submittingServiceLocation",
    ADD_PRICEBOOKITEM_SERVICE: "submittingPriceBookItem",
    EDIT_PRICEBOOKITEM_SERVICE: "submittingPriceBookItem",
    ADD_PRICEBOOKITEM_TAX: "submittingPriceBookItem",
    EDIT_PRICEBOOKITEM_TAX: "submittingPriceBookItem",
    ADD_EQUIPMENT: "submittingEquipment",
    ADD_CONTACT: "submittingContact",
    EDIT_CONTACT: "submittingContact",
}

const ERROR_NAMES_BY_MODE = {
    ADD_JOB: "job",
    EDIT_JOB: "job",
    ADD_CLIENT: "client",
    EDIT_CLIENT: "client",
    ADD_SERVICE_LOCATION: "serviceLocation",
    EDIT_SERVICE_LOCATION: "serviceLocation",
    ADD_PRICEBOOKITEM_SERVICE: "priceBookItem",
    EDIT_PRICEBOOKITEM_SERVICE: "priceBookItem",
    ADD_PRICEBOOKITEM_TAX: "priceBookItem",
    EDIT_PRICEBOOKITEM_TAX: "priceBookItem",
    ADD_EQUIPMENT: "equipment",
    ADD_CONTACT: "contact",
    EDIT_CONTACT: "contact",
}


class JobCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_JOB
        this.addToastToQueue = this.props.addToastToQueue
        this.backDestination = this.props.backDestination

        this.workingTechnicianOptions = window.WORKING_TECHNICIANS || []
        this.similarJobCache = {}
        this.duplicateEquipmentCache = {}

        this.state = {
            jobData: null,
            estimateData: null,
            callbackToData: null,
            clientData: {},
            serviceLocationData: {},
            priceBookItemData: {},
            equipmentData: {},
            contactData: {},

            similarJobData: [],
            duplicateEquipmentData: [],

            attachments: [],

            selectedClient: null,
            selectedServiceLocation: null,
            selectedPriceBookService: null,
            selectedEquipment: [],
            selectedReporter: null,
            selectedPointOfContact: null,

            isDraft: false,

            errors: {
                job: {},
                client: {},
                serviceLocation: {},
                priceBookItem: {},
                equipment: {},
                contact: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            priceBookServices: window.PRICEBOOK_SERVICES || [],
            priceBookTaxes: window.PRICEBOOK_TAXES || [],
            equipmentCategoryOptions: window.EQUIPMENT_CATEGORIES || [],
            equipmentTypeOptions: window.EQUIPMENT_TYPES || [],
            ownershipTypeOptions: window.OWNERSHIP_TYPES || [],
            jobOriginTypeOptions: window.JOB_ORIGIN_TYPES || [],
            allJobOriginTypes: window.ALL_JOB_ORIGIN_TYPES || [],
            priorityLevelsOptions: window.PRIORITY_LEVELS || [],

            useTaxes: window.USE_TAXES,
            pricebook_default_taxable_service: window.PRICEBOOK_DEFAULT_TAXABLE_SERVICE,
            pricebook_default_taxable_part: window.PRICEBOOK_DEFAULT_TAXABLE_PART,
            pricebook_default_taxable_other: window.PRICEBOOK_DEFAULT_TAXABLE_OTHER,

            preferredTimezone: window.PREFERRED_TIMEZONE,
            phoneNumberCountry: window.PHONE_NUMBER_COUNTRY,
            defaultClientType: window.DEFAULT_CLIENT_TYPE,
            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,

            showJobOriginFields: window.SHOW_JOB_ORIGIN_FIELDS,
            showCustomJobIDField: window.JOB_CUSTOM_ID_GENERATION_MODE === CustomIDGenerationModes.manual,

            showQuickBooksRevenueAccountSelect: window.ACCOUNTING_INTEGRATION === 2 && window.QUICKBOOKS_LINE_ITEM_SCHEME === 2,
            showQuickBooksTaxAgencyVendorSelect: window.ACCOUNTING_INTEGRATION === 2,
            showTaxCreateButton: window.ACCOUNTING_INTEGRATION === 0,

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            returnScroll: 0,

            showEquipmentWipedWarning: false,
            showContactsWipedWarning: false,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        const searchParams = new URLSearchParams(document.location.search)
        const startDate = searchParams.get("start_date")
        const endDate = searchParams.get("end_date")
        const resourceId = searchParams.get("technician_id")

        if (this.state.jobData === null) {
            if (this.state.defaultMode === FORM_MODES.EDIT_JOB && window.JOB_ID) {
                const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
                const response = await fetch(endpoint)

                let job

                if (response.ok) {
                    job = await response.json()

                    this.setState((state, props) => {
                        let updatedState = state

                        updatedState.selectedClient = job.service_location.external_client
                        updatedState.selectedServiceLocation = job.service_location
                        updatedState.selectedEquipment = job.equipment
                        updatedState.selectedPriceBookService = state.priceBookServices.find(service => service.description === job.service_name) || null

                        // Because contacts are based on the service location, we can't just pull a full list and find a match.
                        if ([job.reporter_name, job.reporter_phone, job.reporter_email].some(detail => detail !== "")) {
                            updatedState.selectedReporter = {
                                "composite_key": `${job.reporter_name}_${job.reporter_phone}_${job.reporter_phone_extension}_${job.reporter_email}`,
                                "name": job.reporter_name,
                                "phone": job.reporter_phone,
                                "phone_extension": job.reporter_phone_extension,
                                "email": job.reporter_email,
                                "is_ephemeral": job.reporter_is_ephemeral,
                            }
                        }
                        if ([job.point_of_contact_name, job.point_of_contact_phone, job.point_of_contact_email].some(detail => detail !== "")) {
                            updatedState.selectedPointOfContact = {
                                "composite_key": `${job.point_of_contact_name}_${job.point_of_contact_phone}_${job.point_of_contact_phone_extension}_${job.point_of_contact_email}`,
                                "name": job.point_of_contact_name,
                                "phone": job.point_of_contact_phone,
                                "phone_extension": job.point_of_contact_phone_extension,
                                "email": job.point_of_contact_email,
                                "is_ephemeral": job.point_of_contact_is_ephemeral,
                            }
                        }

                        updatedState.jobData = job
                        updatedState.isDraft = job.is_draft

                        updatedState.attachments = deepcopy()(job.attachments)
                        updatedState.labels = deepcopy()(job.labels)

                        // Convert service location to ID
                        updatedState.jobData.service_location = job.service_location.id

                        // Convert assigned technician objects to IDs and filter working technicians
                        const assignedTechnicianIDs = job.assigned_technicians.map(
                            technician => technician.id
                        ).filter(technicianId =>
                            this.workingTechnicianOptions.some(option => option.value === technicianId)
                        )
                        updatedState.jobData.assigned_technicians = assignedTechnicianIDs

                        // Convert equipment to IDs
                        const equipmentIDs = job.equipment.map(equipment => equipment.id)
                        updatedState.jobData.equipment = equipmentIDs

                        // Convert attachments to ids
                        updatedState.jobData.estimate = job.estimate ? job.estimate.id : null
                        updatedState.jobData.callback_to = job.callback_to ? job.callback_to.id : null
                        updatedState.jobData.series_origin = job.series_origin ? job.series_origin.id : null

                        this.resetJobData(updatedState, state, job)

                        return updatedState
                    })
                }
            }
            else if (this.state.defaultMode === FORM_MODES.ADD_JOB) {
                const localTime = dayjs.tz(undefined, window.PREFERRED_TIMEZONE)
                const localDateString = localTime.format("YYYY-MM-DD")

                this.setState((state, props) => {
                    let updatedState = state
                    this.resetJobData(updatedState, state, {})
                    updatedState.jobData.date_received = localDateString
                    updatedState.jobData.attachments = []

                    if (startDate !== null) {
                        updatedState.jobData.estimated_arrival_time = new Date(startDate)
                    }
                    if (startDate !== null && endDate !== null) {
                        const estimatedDurationInSeconds = dayjs(endDate).diff(dayjs(startDate))
                        const estimatedDuration = estimatedDurationInSeconds / 1000

                        updatedState.jobData.estimated_duration = estimatedDuration
                    }
                    if (resourceId !== null) {
                        updatedState.jobData.assigned_technicians = [parseInt(resourceId)]
                    }

                    updatedState.jobData.recurrence_enabled = false
                    updatedState.jobData.recurrence_interval = null
                    updatedState.jobData.recurrence_end = null
                    updatedState.jobData.recurrence_frequency = null
                    updatedState.jobData.weekly_recurrence = null
                    updatedState.jobData.monthly_recurrence = null
                    updatedState.jobData.labels = []


                    return updatedState
                })
            }
        }

        const hasEstimatesEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_estimates_enabled;
        const hasEstimatesPermissions = window.CURRENT_USER?.permissions.estimates_view_permission >= PERMISSION_LEVEL.FULL
        const fromEstimateID = new URLSearchParams(document.location.search).get("from_estimate") || null
        if (hasEstimatesEntitlement && hasEstimatesPermissions && fromEstimateID !== null) {
            if (this.state.estimateData === null) {
                let estimateEndpoint = DjangoUrls["estimates:api-estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, fromEstimateID)

                const estimateResponse = await fetch(estimateEndpoint)
                const estimate = await estimateResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.estimateData = estimate

                    // Split line items into their respective lists
                    updatedState.estimateData.service_charges = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                    updatedState.estimateData.parts = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    updatedState.estimateData.other_charges = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                    updatedState.estimateData.discounts = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                    updatedState.jobData.estimate = estimate.id
                    updatedState.jobData.external_client = estimate.service_location.external_client.id
                    updatedState.jobData.service_location = estimate.service_location.id
                    updatedState.jobData.details = estimate.details
                    updatedState.jobData.equipment_expected = estimate.equipment.length > 0
                    updatedState.jobData.equipment = estimate.equipment.map((equipment)=>equipment.id)


                    updatedState.selectedClient = estimate.service_location.external_client
                    updatedState.selectedServiceLocation = estimate.service_location
                    updatedState.selectedEquipment = estimate.equipment

                    updatedState.jobData.service_name = updatedState.estimateData.service_name
                    updatedState.selectedPriceBookService = state.priceBookServices.find(service => service.description === updatedState.estimateData.service_name) || null
                    updatedState.jobData.estimated_duration = updatedState.selectedPriceBookService?.default_job_duration

                    updatedState.jobData.line_items = [...updatedState.estimateData.service_charges, ...updatedState.estimateData.parts, ...updatedState.estimateData.other_charges, ...updatedState.estimateData.discounts]
                    updatedState.jobData.tax_name = updatedState.estimateData.tax_name
                    updatedState.jobData.tax_percent = updatedState.estimateData.tax_percent
                    updatedState.jobData.tax_breakdown = updatedState.estimateData.tax_breakdown
                    updatedState.jobData.tax_quickbooks_desktop_item_id = updatedState.estimateData.tax_quickbooks_desktop_item_id

                    return updatedState
                })
            }
        }
        else {
            this.setState((state, props) => {
                let updatedState = state

                if (state.jobData.estimate !== null && state.jobData.estimate !== undefined) {
                    updatedState.estimateData = deepcopy()(state.jobData.estimate)
                    updatedState.jobData.estimate = state.jobData.estimate.id
                }
                else {
                    updatedState.estimateData = {}
                }

                updatedState.line_items = []
                return updatedState
            })
        }

        const callbackToID = new URLSearchParams(document.location.search).get("callback_to") || null
        if (callbackToID != null) {
            if (this.state.callbackToData === null) {
                let callbackToEndpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, callbackToID)

                const callbackToResponse = await fetch(callbackToEndpoint)
                const callbackTo = await callbackToResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.callbackToData = callbackTo

                    updatedState.jobData.is_callback = true
                    updatedState.jobData.callback_to = callbackTo.id
                    updatedState.jobData.external_client = callbackTo.service_location.external_client.id
                    updatedState.jobData.service_location = callbackTo.service_location.id

                    updatedState.selectedClient = callbackTo.service_location.external_client
                    updatedState.selectedServiceLocation = callbackTo.service_location
                    updatedState.selectedEquipment = callbackTo.equipment

                    // Because contacts are based on the service location, we can't just pull a full list and find a match.
                    if ([callbackTo.reporter_name, callbackTo.reporter_phone, callbackTo.reporter_email].some(detail => detail !== "")) {
                        updatedState.selectedReporter = {
                            "composite_key": `${callbackTo.reporter_name}_${callbackTo.reporter_phone}_${callbackTo.reporter_phone_extension}_${callbackTo.reporter_email}`,
                            "name": callbackTo.reporter_name,
                            "phone": callbackTo.reporter_phone,
                            "phone_extension": callbackTo.reporter_phone_extension,
                            "email": callbackTo.reporter_email,
                            "is_ephemeral": callbackTo.reporter_is_ephemeral,
                        }
                    }
                    if ([callbackTo.point_of_contact_name, callbackTo.point_of_contact_phone, callbackTo.point_of_contact_email].some(detail => detail !== "")) {
                        updatedState.selectedPointOfContact = {
                            "composite_key": `${callbackTo.point_of_contact_name}_${callbackTo.point_of_contact_phone}_${callbackTo.point_of_contact_phone_extension}_${callbackTo.point_of_contact_email}`,
                            "name": callbackTo.point_of_contact_name,
                            "phone": callbackTo.point_of_contact_phone,
                            "phone_extension": callbackTo.point_of_contact_phone_extension,
                            "email": callbackTo.point_of_contact_email,
                            "is_ephemeral": callbackTo.point_of_contact_is_ephemeral,
                        }
                    }

                    updatedState.jobData.service_name = callbackTo.service_name

                    updatedState.jobData.tax_name = callbackTo.tax_name
                    updatedState.jobData.tax_percent = callbackTo.tax_percent
                    updatedState.jobData.tax_breakdown = callbackTo.tax_breakdown
                    updatedState.jobData.tax_quickbooks_desktop_item_id = callbackTo.tax_quickbooks_desktop_item_id

                    updatedState.jobData.equipment = callbackTo.equipment.map(equipment => equipment.id)
                    updatedState.jobData.origin_type = callbackTo.origin_type
                    updatedState.jobData.origin_id = callbackTo.origin_id

                    updatedState.jobData.details = callbackTo.details
                    updatedState.jobData.labels = callbackTo.labels

                    updatedState.jobData.reporter_name = callbackTo.reporter_name
                    updatedState.jobData.reporter_phone = callbackTo.reporter_phone
                    updatedState.jobData.reporter_phone_extension = callbackTo.reporter_phone_extension
                    updatedState.jobData.reporter_email = callbackTo.reporter_email
                    updatedState.jobData.reporter_is_ephemeral = callbackTo.reporter_is_ephemeral

                    updatedState.jobData.point_of_contact_name = callbackTo.point_of_contact_name
                    updatedState.jobData.point_of_contact_phone = callbackTo.point_of_contact_phone
                    updatedState.jobData.point_of_contact_phone_extension = callbackTo.point_of_contact_phone_extension
                    updatedState.jobData.point_of_contact_email = callbackTo.point_of_contact_email
                    updatedState.jobData.point_of_contact_is_ephemeral = callbackTo.point_of_contact_is_ephemeral

                    updatedState.jobData.dispatcher_notes = callbackTo.dispatcher_notes

                    return updatedState
                })
            }
        }
        else {
            this.setState((state, props) => {
                let updatedState = state

                if (state.jobData.callback_to !== null && state.jobData.callback_to !== undefined) {
                    updatedState.callbackToData = deepcopy()(state.jobData.callback_to)
                    updatedState.jobData.callback_to = state.jobData.callback_to.id
                }
                else {
                    updatedState.callbackToData = {}
                }

                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            const backButton = document.querySelector(".back-button");
            backButton && (backButton.style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]);
            this.setState(history.state)
        }

        this.fetchDuplicateEquipment()
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, this.fetchDuplicateEquipment)
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        }, this.fetchDuplicateEquipment)
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)

                if (newFormMode === FORM_MODES.EDIT_PRICEBOOKITEM_TAX) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].returnMode = this.state.mode
                }
            }
            else {
                if (newFormMode === FORM_MODES.ADD_CLIENT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].client_type = window.DEFAULT_CLIENT_TYPE || ClientTypes.business
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].external_client = state.jobData.external_client  // Set the client id on the new service location
                }
                else if (newFormMode === FORM_MODES.ADD_PRICEBOOKITEM_SERVICE) {
                    // Set the type to `service` on the new PriceBook Item. Also set typical defaults
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].pricebook_item_type = PriceBookItemTypes.service
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].default_unit_type = LineItemUnitTypes.hourly
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_active = true
                }
                else if (newFormMode === FORM_MODES.ADD_PRICEBOOKITEM_TAX) {
                    // Set the type to `tax` on the new PriceBook Item.
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].pricebook_item_type = PriceBookItemTypes.tax
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_active = true
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].returnMode = this.state.mode
                }
                else if (EQUIPMENT_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].service_location = state.jobData.service_location  // Set the service location id on the new equipment

                    // Auto-set if there's only one option; the associated field is disabled if there's only one as well
                    const valueOptions = state.equipmentCategoryOptions.filter(option => option.value !== "")
                    if (valueOptions.length == 1) {
                        updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].equipment_category = valueOptions[0].value
                    }
                }
                else if (newFormMode === FORM_MODES.ADD_CONTACT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].attached_to = "service_location"
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_ephemeral = false
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    resetJobData = (updatedState, state, jobData) => {
        updatedState.jobData = jobData
        updatedState.jobData.is_draft = state.isDraft

        if (this.workingTechnicianOptions.length === 1) {
            updatedState.jobData.assigned_technicians = [this.workingTechnicianOptions[0].value]
        }
    }

    updateClientSelection = (selectedClient) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedClient !== null) {
                // If a different client is selected, wipe the selected location and equipment
                if (state.jobData.external_client !== undefined && selectedClient.id !== state.jobData.external_client) {
                    updatedState.selectedServiceLocation = null
                    updatedState.showEquipmentWipedWarning = state.selectedEquipment.length !== 0
                    updatedState.selectedEquipment = []
                    updatedState.showContactsWipedWarning = state.selectedReporter !== null || state.selectedPointOfContact !== null
                    updatedState.selectedReporter = null
                    updatedState.selectedPointOfContact = null

                    updatedState.jobData.service_location = null
                    updatedState.jobData.equipment = []

                    updatedState.jobData.reporter = null
                    updatedState.jobData.reporter_name = ""
                    updatedState.jobData.reporter_phone = ""
                    updatedState.jobData.reporter_phone_extension = ""
                    updatedState.jobData.reporter_email = ""

                    updatedState.jobData.point_of_contact = null
                    updatedState.jobData.point_of_contact_name = ""
                    updatedState.jobData.point_of_contact_phone = ""
                    updatedState.jobData.point_of_contact_phone_extension = ""
                    updatedState.jobData.point_of_contact_email = ""
                }

                // Set the selection, set the data
                updatedState.selectedClient = selectedClient
                updatedState.jobData.external_client = selectedClient.id

                // Set the service location if only one exists
                if (selectedClient.service_locations.length == 1) {
                    updatedState.selectedServiceLocation = selectedClient.service_locations[0]
                    updatedState.jobData.service_location = updatedState.selectedServiceLocation.id
                }
            }
            else {
                // Client was unset. Unset data
                updatedState.selectedClient = null
                updatedState.selectedServiceLocation = null
                updatedState.showEquipmentWipedWarning = state.selectedEquipment.length !== 0
                updatedState.selectedEquipment = []
                updatedState.showContactsWipedWarning = state.selectedReporter !== null || state.selectedPointOfContact !== null
                updatedState.selectedReporter = null
                updatedState.selectedPointOfContact = null
            }

            return updatedState
        })
    }

    updateServiceLocationSelection = (selectedServiceLocation) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedServiceLocation !== null) {
                // If a different location is selected, wipe the selected equipment
                if (state.jobData.service_location !== undefined && selectedServiceLocation.id !== state.jobData.service_location) {
                    updatedState.showEquipmentWipedWarning = state.selectedEquipment.length !== 0
                    updatedState.selectedEquipment = []
                    updatedState.showContactsWipedWarning = state.selectedReporter !== null || state.selectedPointOfContact !== null
                    updatedState.selectedReporter = null
                    updatedState.selectedPointOfContact = null

                    updatedState.jobData.equipment = []

                    updatedState.jobData.reporter = null
                    updatedState.jobData.reporter_name = ""
                    updatedState.jobData.reporter_phone = ""
                    updatedState.jobData.reporter_phone_extension = ""
                    updatedState.jobData.reporter_email = ""

                    updatedState.jobData.point_of_contact = null
                    updatedState.jobData.point_of_contact_name = ""
                    updatedState.jobData.point_of_contact_phone = ""
                    updatedState.jobData.point_of_contact_phone_extension = ""
                    updatedState.jobData.point_of_contact_email = ""
                }

                // Set the selection, set the data
                updatedState.selectedServiceLocation = selectedServiceLocation
                updatedState.jobData.service_location = selectedServiceLocation.id

            }
            else {
                // Service location was unset. Unset data
                updatedState.selectedServiceLocation = null
                updatedState.showEquipmentWipedWarning = state.selectedEquipment.length !== 0
                updatedState.selectedEquipment = []
                updatedState.showContactsWipedWarning = state.selectedReporter !== null || state.selectedPointOfContact !== null
                updatedState.selectedReporter = null
                updatedState.selectedPointOfContact = null
            }

            return updatedState
        }, this.fetchSimilarJobs)
    }

    updatePriceBookServiceSelection = (selectedPriceBookService) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedPriceBookService !== null) {
                if (state.jobData.service_name !== selectedPriceBookService.description) {
                    updatedState.jobData.estimated_duration = selectedPriceBookService.default_job_duration
                }
                else if (updatedState.jobData.estimated_duration === undefined || updatedState.jobData.estimated_duration === "") {
                    updatedState.jobData.estimated_duration = selectedPriceBookService.default_job_duration
                }

                updatedState.selectedPriceBookService = selectedPriceBookService

                updatedState.jobData.service_name = selectedPriceBookService.description
            }
            else {
                updatedState.jobData.estimated_duration = ""

                updatedState.selectedPriceBookService = null

                updatedState.jobData.service_name = ""
            }

            return updatedState
        }, this.fetchSimilarJobs)
    }

    updatePriceBookTaxSelection = (selectedPriceBookTax, then=null) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedPriceBookTax) {
                // Update the pricebook tax list with any changes
                if (getPricebookTaxById(selectedPriceBookTax.id, state.priceBookTaxes)) {
                    const updateIndex = state.priceBookTaxes.findIndex(tax => tax.id === selectedPriceBookTax.id)
                    updatedState.priceBookTaxes[updateIndex] = selectedPriceBookTax
                }
                else {
                    updatedState.priceBookTaxes.push(selectedPriceBookTax)
                }
            }

            // If no return mode is set (i.e. we're not on the pricebook item form, use the current mode)
            const parentMode = state[FORM_DATA_NAMES_BY_MODE[state.mode]].returnMode || state.mode

            if (!PRIMARY_FORM_MODES.includes(parentMode)) {
                updatedState[FORM_DATA_NAMES_BY_MODE[parentMode]].selectedPriceBookTax = selectedPriceBookTax
                updatedState[FORM_DATA_NAMES_BY_MODE[parentMode]].default_pricebook_tax = selectedPriceBookTax?.id || null
            }

            return updatedState
        }, then)
    }

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.jobData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    updateEquipmentSelection = (selectedEquipment) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.selectedEquipment = selectedEquipment
            updatedState.jobData.equipment = selectedEquipment.map(equipment => equipment.id)

            return updatedState
        })
    }

    updateReporterSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedContact !== null) {
                updatedState.selectedReporter = selectedContact

                updatedState.jobData.reporter_name = selectedContact.name
                updatedState.jobData.reporter_phone = selectedContact.phone
                updatedState.jobData.reporter_phone_extension = selectedContact.phone_extension
                updatedState.jobData.reporter_email = selectedContact.email
                updatedState.jobData.reporter_is_ephemeral = selectedContact.is_ephemeral
            }
            else {
                updatedState.selectedReporter = null

                updatedState.jobData.reporter_name = ""
                updatedState.jobData.reporter_phone = ""
                updatedState.jobData.reporter_phone_extension = ""
                updatedState.jobData.reporter_email = ""
                updatedState.jobData.reporter_is_ephemeral = false
            }

            return updatedState
        })
    }

    updatePointOfContactSelection = (selectedContact) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedContact !== null) {
                updatedState.selectedPointOfContact = selectedContact

                updatedState.jobData.point_of_contact_name = selectedContact.name
                updatedState.jobData.point_of_contact_phone = selectedContact.phone
                updatedState.jobData.point_of_contact_phone_extension = selectedContact.phone_extension
                updatedState.jobData.point_of_contact_email = selectedContact.email
                updatedState.jobData.point_of_contact_is_ephemeral = selectedContact.is_ephemeral
            }
            else {
                updatedState.selectedPointOfContact = null

                updatedState.jobData.point_of_contact_name = ""
                updatedState.jobData.point_of_contact_phone = ""
                updatedState.jobData.point_of_contact_phone_extension = ""
                updatedState.jobData.point_of_contact_email = ""
                updatedState.jobData.point_of_contact_is_ephemeral = false
            }

            return updatedState
        })
    }

    fetchSimilarJobs = async () => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.similarJobData = []
            return updatedState
        })

        if (this.state.selectedServiceLocation !== null && this.state.selectedPriceBookService !== null && this.state.mode === FORM_MODES.ADD_JOB && window.CURRENT_USER?.permissions.jobs_list_permission >= PERMISSION_LEVEL.RESTRICTED) {
            const params = new URLSearchParams({
                "service_location": this.state.selectedServiceLocation.id,
                "service_name": this.state.selectedPriceBookService.description,
            })
            const endpoint = DjangoUrls["jobs:api-jobs-similar-list"](window.MARKETPLACE_ENTITY_SLUG) + `?${params.toString()}`
            let similarJobs

            if (params.toString() in this.similarJobCache) {
                similarJobs = this.similarJobCache[params.toString()]
            }
            else {
                const similarJobResponse = await fetch(endpoint)
                similarJobs = await similarJobResponse.json()
                this.similarJobCache[params.toString()] = similarJobs
            }

            this.setState((state, props) => {
                let updatedState = state
                updatedState.similarJobData = similarJobs
                return updatedState
            })
        }
    }

    fetchDuplicateEquipment = debounce(
        async () => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.duplicateEquipmentData = []
                return updatedState
            })

            if (valueIsDefined(this.state.equipmentData.manufacturer) && valueIsDefined(this.state.equipmentData.model_number) && valueIsDefined(this.state.equipmentData.serial_number)) {
                const params = new URLSearchParams({
                    "manufacturer": this.state.equipmentData.manufacturer,
                    "model_number": this.state.equipmentData.model_number,
                    "serial_number": this.state.equipmentData.serial_number,
                })

                const endpoint = DjangoUrls["equipment:api-equipment-duplicates-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id) + `?${params.toString()}`
                let duplicateEquipment

                if (params.toString() in this.duplicateEquipmentCache) {
                    duplicateEquipment = this.duplicateEquipmentCache[params.toString()]
                }
                else {
                    const duplicateEquipmentResponse = await fetch(endpoint)
                    duplicateEquipment = await duplicateEquipmentResponse.json()
                    this.duplicateEquipmentCache[params.toString()] = duplicateEquipment
                }

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.duplicateEquipmentData = duplicateEquipment.filter(equipment => equipment.id !== this.state.equipmentData.id)
                    return updatedState
                })
            }
        }, 500
    )

    // Crud job

    createJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-list"](window.MARKETPLACE_ENTITY_SLUG)
        let successUrl

        const onSuccess = (job) => {
            if (window.CURRENT_USER?.permissions.jobs_list_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["dashboard:dashboard"](window.MARKETPLACE_ENTITY_SLUG)
            } else {
                successUrl = this.backDestination ?? DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)
            }

            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job ${job.recurrence_enabled ? "series" : `"${job.custom_id || job.id}"`} scheduled`,
                cta: (
                    !job.recurrence_enabled && window.CURRENT_USER?.permissions.jobs_view_permission >= PERMISSION_LEVEL.FULL ?
                    {
                        children: "View",
                        destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)
                    }
                    :
                    undefined
                ),
                path: successUrl.split("?")[0],
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Job ${this.state.jobData.recurrence_enabled ? "series " : ""}could not be scheduled`,
            })
        }

        this.CUDJob(endpoint, "POST", onSuccess, onError)
    }

    createDraftJob = async (preview=false) => {
        const endpoint = DjangoUrls["jobs:api-jobs-create-as-draft"](window.MARKETPLACE_ENTITY_SLUG)
        let successUrl

        const onSuccess = (job) => {
            if (preview) {
                successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)
            } else {
                if (window.CURRENT_USER?.permissions.jobs_list_permission < PERMISSION_LEVEL.FULL) {
                    successUrl = DjangoUrls["dashboard:dashboard"](window.MARKETPLACE_ENTITY_SLUG)
                } else {
                    successUrl = this.backDestination ?? DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)
                }
            }
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${job.custom_id || job.id}" draft saved`,
                cta:
                    !preview && window.CURRENT_USER?.permissions.jobs_view_permission >= PERMISSION_LEVEL.FULL ?
                    {
                        children: "View",
                        destination: DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)
                    }
                    :
                    undefined,
                path: successUrl,
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job draft could not be saved",
            })
        }

        this.CUDJob(endpoint, "POST", onSuccess, onError)
    }

    updateJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const onSuccess = (job) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${job.custom_id || job.id}" updated`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be updated",
            })
        }

        this.CUDJob(endpoint, "PUT", onSuccess, onError)
    }

    deleteDraftJob = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        let successUrl = this.backDestination ?? DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)

        if (valueIsDefined(this.state.jobData.estimate)) {
            successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.estimate)
        }

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" draft deleted`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_confirm_delete_draft .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_delete_draft .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job draft could not be deleted",
            })
            const deleteDraftModal = document.querySelector("#message_modal_confirm_delete_draft .modal__close")
            if (deleteDraftModal) {
                deleteDraftModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        this.CUDJob(endpoint, "DELETE", onSuccess, onError)
    }

    CUDJob = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "jobData"
        const submittingName = "submittingJob"
        const errorDictName = "job"

        const dataManipulator = (data, state) => {
            // Convert blank Job ID value to null
            data.custom_id = data.custom_id || null

            // Set a blank label list if there aren't any selected
            data.labels = data.labels || []

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, custom_id must make a unique set.") {
                errorDict["custom_id"] = "A job with this ID already exists."
            }
            else if (fieldName === "labels" && Array.isArray(message)) {
                errorDict["labels"] = "Labels must be fewer than 100 characters."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud Client

    createClient = async () => {
        const endpoint = DjangoUrls["clients:api-clients-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const onSuccess = (client) => {
            this.updateClientSelection(client)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${client.name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be created",
            })
        }

        this.createUpdateClient(endpoint, endpointMethod, onSuccess, onError)
    }

    updateClient = async () => {
        const endpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        const endpointMethod = "PUT"

        const onSuccess = (client) => {
            this.updateClientSelection(client)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${client.name}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be updated",
            })
        }

        this.createUpdateClient(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateClient = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "clientData"
        const submittingName = "submittingClient"
        const errorDictName = "client"

        const dataManipulator = (data, state) => {
            data.industry_type = data.industry_type || null  // Convert blank Industry Type value to null
            data.default_invoice_net = data.default_invoice_net !== "" ? data.default_invoice_net : null  // Convert blank net value to null
            data.contacts = data.contacts || []

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A client with this name already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud service location

    createServiceLocation = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        const endpointMethod = "POST"

        const onSuccess = (serviceLocation) => {
            this.updateServiceLocationSelection(serviceLocation)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "lg",
                title: "Service Location created",
                subtitle: renderServiceLocationString(serviceLocation)
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Location could not be created"
            })
        }

        this.createUpdateServiceLocation(endpoint, endpointMethod, onSuccess, onError)
    }

    updateServiceLocation = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state.selectedServiceLocation.id)
        const endpointMethod = "PUT"

        const onSuccess = (serviceLocation) => {
            this.updateServiceLocationSelection(serviceLocation)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "lg",
                title: "Service Location updated",
                subtitle: renderServiceLocationString(serviceLocation)
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Location could not be updated"
            })
        }

        this.createUpdateServiceLocation(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateServiceLocation = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "serviceLocationData"
        const submittingName = "submittingServiceLocation"
        const errorDictName = "serviceLocation"

        const dataManipulator = (data, state) => {
            data.default_invoice_net = data.default_invoice_net !== "" ? data.default_invoice_net : null  // Convert blank net value to null
            data.contacts = data.contacts || []
            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields external_client, name, physical_address_formatted must make a unique set.") {
                errorDict["physical_address_street"] = "A service location with this name and address already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud PriceBook Item

    createPriceBookService = async (isDraft) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (priceBookItem) => {
            this.updatePriceBookServiceSelection(priceBookItem)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Service "${priceBookItem.description}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service could not be created"
            })
        }

        this.CUDPriceBookItem(endpoint, "POST", onSuccess, onError)
    }

    updatePriceBookService = async () => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedPriceBookService.id)

        const onSuccess = (priceBookItem) => {
            this.updatePriceBookServiceSelection(priceBookItem)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Service "${priceBookItem.description}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service could not be updated"
            })
        }

        this.CUDPriceBookItem(endpoint, "PUT", onSuccess, onError)
    }

    createPriceBookTax = async (isDraft) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG)

        const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

        const onSuccess = (priceBookItem) => {
            const switchToForm = () => {
                this.switchToPrimaryForm()

                if (CLIENT_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.clientData), null)
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
            }
            this.updatePriceBookTaxSelection(priceBookItem, switchToForm)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Tax Rate "${priceBookItem.description}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Tax Rate could not be created"
            })
        }

        this.CUDPriceBookItem(endpoint, "POST", onSuccess, onError)
    }

    updatePriceBookTax = async () => {
        const priceBookItemID = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].id
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, priceBookItemID)

        const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

        const onSuccess = (priceBookItem) => {
            const switchToForm = () => {
                this.switchToPrimaryForm()

                if (CLIENT_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.clientData), null)
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
            }
            this.updatePriceBookTaxSelection(priceBookItem, switchToForm)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Tax Rate "${priceBookItem.description}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Tax Rate could not be updated"
            })
        }

        this.CUDPriceBookItem(endpoint, "PUT", onSuccess, onError)
    }

    CUDPriceBookItem = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "priceBookItemData"
        const submittingName = "submittingPriceBookItem"
        const errorDictName = "priceBookItem"

        const dataManipulator = (data, state) => {
            let finalData = deepcopy()(data)
            finalData.confirmed = true

            // If this isn't a service charge, remove service-charge-specific data
            if (state.priceBookItemData.pricebook_item_type !== PriceBookItemTypes.service) {
                delete finalData.default_unit_type
                delete finalData.expected_job_duration
            }

            return finalData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields description, service_company must make a unique set.") {
                errorDict["description"] = "A PriceBook item with this name already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud equipment

    createEquipment = async () => {
        const endpoint = DjangoUrls["equipment:api-service-location-equipment-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedServiceLocation.id)
        const endpointMethod = "POST"

        const onSuccess = (equipment) => {
            this.updateEquipmentSelection([...this.state.selectedEquipment, equipment])
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Equipment "${equipment.display_name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Equipment could not be created",
            })
        }

        this.createUpdateEquipment(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateEquipment = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "equipmentData"
        const submittingName = "submittingEquipment"
        const errorDictName = "equipment"

        const dataManipulator = (data, state) => {
            data.equipment_category = data.equipment_category || null
            data.equipment_type = data.equipment_type || null
            data.warranties = data.warranties || []

            if (data.ownership_type === "") {
                delete data.ownership_type
            }

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_location, display_name must make a unique set.") {
                errorDict["display_name"] = "A piece of equipment with this name at this service location already exists."
            }
            else if (fieldName === "non_field_errors" && message === "There are other pieces of equipment that belong to this Client with the same manufacturer, model number, and serial number.") {
                errorDict["identifying_info"] = "Please ensure this equipment's manufacturer, model number, and serial number are unique for this Client."
            }
            else if (fieldName === "warranties") {
                errorDict["warranties"] = "Please correct the warranty errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((warrantyError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].warranties[index].errors = warrantyError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud Contact

    createContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef

        const contactData = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]]

        let endpoint

        if (attachedTo === "external_client") {
            endpoint = DjangoUrls["clients:api-clients-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        }
        else {
            endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state.selectedServiceLocation.id)
        }

        const endpointMethod = "POST"

        const onSuccess = (contact) => {
            this[populationRef](contact)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: contact.is_ephemeral ? "lg" : "md",
                title: `Contact ${contact.is_ephemeral ? "added" : "created"}`,
                subtitle: `This contact will not be saved to the contact list`
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Contact could not be ${contactData.is_ephemeral ? "added" : "created"}`,
            })
        }

        this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
    }

    updateContact = async () => {
        const attachedTo = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].attached_to
        const populationRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].populationRef
        const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef
        let endpoint

        if (attachedTo === "external_client") {
            endpoint = DjangoUrls["clients:api-clients-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state[dataRef].id)
        }
        else {
            endpoint = DjangoUrls["clients:api-clients-service-locations-contacts-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state.selectedServiceLocation.id, this.state[dataRef].id)
        }

        const endpointMethod = "PUT"
        const onSuccess = (contact) => {
            this[populationRef](contact)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Contact updated",
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Contact could not be updated",
            })
        }

        this.createUpdateContact(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateContact = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "contactData"
        const submittingName = "submittingContact"
        const errorDictName = "contact"

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message.endsWith("name, phone, phone_extension, email must make a unique set.")) {
                errorDict["non_field_error"] = "A contact with these details already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "JOB_PREVIEW":
                this.createDraftJob(true)
                break
            case "JOB_CREATE":
                this.createJob()
                break
            case "JOB_CREATE_DRAFT":
                this.createDraftJob()
                break
            case "JOB_DELETE_DRAFT":
                window.deleteDraftJob = this.deleteDraftJob
                document.querySelector("#message_modal_confirm_delete_draft").style.display = ""
                window.MicroModal.show("message_modal_confirm_delete_draft")
                break
            case "JOB_UPDATE":
                this.updateJob()
                break
            case "JOB_CANCEL_EDITS":
                location.assign(DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID))
                break
            case "CLIENT_CREATE":
                this.createClient()
                break
            case "CLIENT_UPDATE":
                this.updateClient()
                break
            case "CLIENT_CANCEL_CREATE":
                this.switchToPrimaryForm()
                break
            case "CLIENT_CANCEL_EDITS":
                this.switchToPrimaryForm()
                break
            case "SERVICE_LOCATION_CREATE":
                this.createServiceLocation()
                break
            case "SERVICE_LOCATION_UPDATE":
                this.updateServiceLocation()
                break
            case "PRICEBOOK_SERVICE_CREATE":
                this.createPriceBookService()
                break
            case "PRICEBOOK_SERVICE_UPDATE":
                this.updatePriceBookService()
                break
            case "PRICEBOOK_TAX_CREATE":
                this.createPriceBookTax()
                break
            case "PRICEBOOK_TAX_UPDATE":
                this.updatePriceBookTax()
                break
            case "PRICEBOOK_TAX_CANCEL_CREATE":
                const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

                this.switchToPrimaryForm()

                if (CLIENT_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.clientData), null)
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
                break
            case "EQUIPMENT_CREATE":
                this.createEquipment()
                break
            case "CONTACT_CREATE":
                this.createContact()
                break
            case "CONTACT_UPDATE":
                const dataRef = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].dataRef

                if (this.state[dataRef].is_ephemeral) {
                    this.createContact()
                }
                else {
                    this.updateContact()
                }
                break
            case "CONTACT_CANCEL_CREATE":
                this.switchToPrimaryForm()
                break
            case "CONTACT_CANCEL_EDITS":
                this.switchToPrimaryForm()
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.jobData === null || this.state.estimateData === null || this.state.callbackToData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <JobForm
                    mode={this.state.mode}
                    submitting={this.state.submittingJob}
                    job={this.state.jobData}
                    estimate={this.state.estimateData}
                    callbackTo={this.state.callbackToData}
                    errors={this.state.errors.job}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("jobData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateClientSelection={this.updateClientSelection}
                    updateServiceLocationSelection={this.updateServiceLocationSelection}
                    updatePriceBookServiceSelection={this.updatePriceBookServiceSelection}
                    updateEquipmentSelection={this.updateEquipmentSelection}
                    updateReporterSelection={this.updateReporterSelection}
                    updatePointOfContactSelection={this.updatePointOfContactSelection}
                    workingTechnicianOptions={this.workingTechnicianOptions}
                    selectedClient={this.state.selectedClient}
                    selectedServiceLocation={this.state.selectedServiceLocation}
                    selectedPriceBookService={this.state.selectedPriceBookService}
                    selectedEquipment={this.state.selectedEquipment}
                    selectedReporter={this.state.selectedReporter}
                    selectedPointOfContact={this.state.selectedPointOfContact}
                    showEquipmentWipedWarning={this.state.showEquipmentWipedWarning}
                    showContactsWipedWarning={this.state.showContactsWipedWarning}
                    similarJobs={this.state.similarJobData}
                    jobOriginTypeOptions={this.state.jobOriginTypeOptions}
                    allJobOriginTypes={this.state.allJobOriginTypes}
                    priorityLevelsOptions={this.state.priorityLevelsOptions}
                    preferredTimezone={this.state.preferredTimezone}
                    showJobOriginFields={this.state.showJobOriginFields}
                    showCustomJobIDField={this.state.showCustomJobIDField}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    fileStackAPIKey={this.state.fileStackAPIKey}
                    fileStackPolicy={this.state.fileStackPolicy}
                    fileStackSignature={this.state.fileStackSignature}
                    updateAttachments={this.updateAttachments}
                    returnScroll={this.state.returnScroll}
                ></JobForm>
            }
            else if (CLIENT_FORM_MODES.includes(this.state.mode)) {
                return <ClientForm
                    mode={this.state.mode}
                    submitting={this.state.submittingClient}
                    client={this.state.clientData}
                    errors={this.state.errors.client}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("clientData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    showServiceLocationSelect={false}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    defaultClientType={this.state.defaultClientType}
                    useTaxes={this.state.useTaxes}
                    priceBookTaxes={this.state.priceBookTaxes}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    selectedPriceBookTax={(
                        this.state.clientData.selectedPriceBookTax ||
                        (this.state.clientData.default_pricebook_tax && getPricebookTaxById(this.state.clientData.default_pricebook_tax, this.state.priceBookTaxes))
                    )}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    returnScroll={0}
                />
            }
            else if (SERVICE_LOCATION_FORM_MODES.includes(this.state.mode)) {
                return <ServiceLocationForm
                    mode={this.state.mode}
                    submitting={this.state.submittingServiceLocation}
                    client={this.state.selectedClient}
                    serviceLocation={this.state.serviceLocationData}
                    errors={this.state.errors.serviceLocation}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("serviceLocationData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    useTaxes={this.state.useTaxes}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    selectedPriceBookTax={(
                        this.state.serviceLocationData.selectedPriceBookTax ||
                        (this.state.serviceLocationData.default_pricebook_tax && getPricebookTaxById(this.state.serviceLocationData.default_pricebook_tax, this.state.priceBookTaxes))
                    )}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    returnScroll={0}
                />
            }
            else if (PRICEBOOK_SERVICE_FORM_MODES.includes(this.state.mode)) {
                return <PriceBookItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingPriceBookItem}
                    priceBookItem={this.state.priceBookItemData}
                    errors={this.state.errors.priceBookItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("priceBookItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    showQuickBooksRevenueAccountSelect={this.state.showQuickBooksRevenueAccountSelect}
                    showQuickBooksTaxAgencyVendorSelect={this.state.showQuickBooksTaxAgencyVendorSelect}
                    useTaxes={this.state.useTaxes}
                    pricebookDefaultTaxableService={this.state.pricebook_default_taxable_service}
                    pricebookDefaultTaxablePart={this.state.pricebook_default_taxable_part}
                    pricebookDefaultTaxableOther={this.state.pricebook_default_taxable_other}
                    returnScroll={0}
                ></PriceBookItemForm>
            }
            else if (EQUIPMENT_FORM_MODES.includes(this.state.mode)) {
                return <EquipmentForm
                    mode={this.state.mode}
                    submitting={this.state.submittingEquipment}
                    equipment={this.state.equipmentData}
                    errors={this.state.errors.equipment}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("equipmentData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    equipmentCategoryOptions={this.state.equipmentCategoryOptions}
                    equipmentTypeOptions={this.state.equipmentTypeOptions}
                    ownershipTypeOptions={this.state.ownershipTypeOptions}
                    duplicateEquipment={this.state.duplicateEquipmentData}
                    returnScroll={0}
                ></EquipmentForm>
            }
            else if (CONTACT_FORM_MODES.includes(this.state.mode)) {
                return <ContactForm
                    mode={this.state.mode}
                    submitting={this.state.submittingContact}
                    contact={this.state.contactData}
                    errors={this.state.errors.contact}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("contactData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                ></ContactForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default JobCreateContainer;
